const reese = reese || {};

(function () {

	reese.language = {

		init: function() {
			document.addEventListener('keydown', reese.language.close, 1);
			document.querySelectorAll('.about__brand-link').forEach( function(elem) { elem.addEventListener('click', reese.language.brander, 1); });
			document.querySelectorAll('.contact .button, .introduction .button').forEach( function(elem) { elem.addEventListener('click', reese.language.tagger, 1); });
			document.querySelector('.selector__initial').addEventListener('click', reese.language.toggle, 1);
		},

		brander: function(e) {
			e.preventDefault();
			let lang = document.documentElement.lang;
			let url = e.target.href;
			if (lang === 'es') {
				document.querySelector('.dialog').showModal();
				document.querySelector('.dialog__button').href = url;
			} else {
				window.location = url;
			}
		},

		close: function(e) {
			let code = (e.keyCode) ? e.keyCode : e.which;
			if (code === 27) {
				let t = document.querySelector('.selector__initial');
				t.attributes['aria-expanded'].value = 'false',
				t.attributes['aria-label'].value = t.attributes['aria-label'].value = t.attributes['aria-label'].value.replace('Hide','Show');
				t.classList.remove('open');
			}
		},

		tagger: function(e) {
			e.preventDefault();
			let lang = document.documentElement.lang;
			let url = e.target.href;
			let id = 'AW-10805203484/' + e.target.dataset.sendto;
			let callback = function() {
				if (lang === 'es') {
					document.querySelector('.dialog').showModal();
					document.querySelector('.dialog__button').href = url;
				} else {
					window.location = url;
				}
			}
			gtag('event', 'conversion', {
				'send_to': id,
				'event_callback': callback
			});
		},

		toggle: function() {
			let t = document.querySelector('.selector__initial');
			let a = !('true' === t.attributes['aria-expanded'].value);

			t.attributes['aria-expanded'].value = a;
			if (a) {
				t.attributes['aria-label'].value = t.attributes['aria-label'].value.replace('Show','Hide');
			} else {
				t.attributes['aria-label'].value = t.attributes['aria-label'].value.replace('Hide','Show');
			}
			t.classList.toggle('open');
		}

	}	

})();

const ready = () => {
	reese.language.init();
};

window.addEventListener('DOMContentLoaded', ready, false);
